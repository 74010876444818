<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-if="isLoading" class="mt-5">
    <PagePreloader layout="list"/>
  </div>
  <div v-else>
    <div class="">
      <div class="" v-bind:class="{'open-filter-box': filterBoxActive}">
        <div class="add-property-title  align-items-baseline">
          <div> {{ $t('admin-dashboard-title') }}</div>
        </div>

        <div v-if="statisticsData && statisticsData.length > 0">
          <div class="d-flex">
            <div>
              {{ $t('vendor-orders-count') }}
            </div>
            <div class=" ms-auto  sort-filter d-none d-md-flex">
              <SortingDropdown :title="$t('period')" :sorting-options="periodOptions"
                               @sort="handlePeriodChange"/>
            </div>
          </div>
          <div ref="chartContainer" class="my-4">
            <DashboardChart :width="chartWidth" :data="statisticsData"/>
          </div>
        </div>

        <div class="stats-row row g-1  d-flex" v-if="statistics.hasOwnProperty('product')">
          <div class=" col-md-3 ">
            <div class="stats-inner-grid-item">
              <div> {{ $t('vendor-all-products') }}</div>
              <div class="count-number" v-if="true"> {{ statistics.product.products_published }}</div>
              <div class="count-number" v-else> -</div>
            </div>

          </div>
          <div class=" col-md-3" v-if="false">
            <div class="stats-inner-grid-item">
              <div> {{ $t('vendor-products-views') }}</div>
              <div class="count-number"> --</div>
            </div>

          </div>
          <div class=" col-md-3">
            <div class="stats-inner-grid-item">
              <div> {{ $t('vendor-new-orders') }} ({{ $t('last') }} 7 {{ $t('days') }})</div>
              <div class="count-number" v-if="true"> {{ statistics.order.last_7_days_orders }}</div>
              <div class="count-number" v-else> -</div>
            </div>
          </div>
          <div class=" col-md-3">
            <div class="stats-inner-grid-item">
              <div> {{ $t('vendor-total-orders') }}</div>
              <div class="count-number" v-if="true"> {{ statistics.order.total_orders }}</div>
              <div class="count-number" v-else> -</div>
            </div>
          </div>
        </div>

        <div class=" orders-wrapper" v-if="orders">
          <div class="ms-2 d-flex align-items-center ">
            <div>
              <svg width="15" height="9" viewBox="0 0 15 9" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <line x1="4.5" y1="0.5" x2="14.5" y2="0.5" stroke="#FF7A00" stroke-linecap="round"/>
                <line x1="0.5" y1="0.5" x2="1.5" y2="0.5" stroke="#FF7A00" stroke-linecap="round"/>
                <line x1="4.5" y1="4.5" x2="14.5" y2="4.5" stroke="#FF7A00" stroke-linecap="round"/>
                <line x1="0.5" y1="4.5" x2="1.5" y2="4.5" stroke="#FF7A00" stroke-linecap="round"/>
                <line x1="4.5" y1="8.5" x2="14.5" y2="8.5" stroke="#FF7A00" stroke-linecap="round"/>
                <line x1="0.5" y1="8.5" x2="1.5" y2="8.5" stroke="#FF7A00" stroke-linecap="round"/>
              </svg>
            </div>
            <div class="stats-title"> {{ $t('vendor-last-orders') }}</div>
          </div>
          <div class=" labels-order  d-none d-md-flex ">
            <div class="order-id col-1"> #</div>
            <div class="client-name col-2">{{ $t('vendor-client-name') }}</div>
            <div class="client-phone col-2">{{ $t('vendor-client-phone') }}</div>
            <div class="order-status col-2 ">
              {{ $t('vendor-order-status') }}
            </div>
            <div class="order-amount col-2"> {{ $t('vendor-order-amount') }}</div>
            <div class="date col-2"> {{ $t('vendor-order-date') }}</div>
          </div>


          <div class="inquiry-item" v-for="order in orders">
            <AdminVendorListItem
              :id="order.id"
              :date="order.createdAt"
              :orderstatus="getOrderStatusNameById(order.status)"
              :orderid="order.id"
              :name="order.name"
              :phone="order.phone"
              :orderamount="order.totalSum"/>
          </div>
        </div>

        <div class="row views-sales-stats" v-if="false">
          <div class="inquiry-item col-md-6">
            <DashboardVendorStats
              :type="'views'"
            />
          </div>
          <div class="inquiry-item col-md-6">
            <DashboardVendorStats
              :type="'inquiries'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
